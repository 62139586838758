.custom-modal-form{
    max-width: 688px;
    min-width: 300px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 2px;
    background-color: #fff;
}

.custom-modal-header-text{
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.custom-modal {
    padding: 0px;
}

.custom-modal-dialog{
    margin: 0px;
}


.custom-modal-text {
    max-width: 500px;
    margin-top: 16px;
    margin-bottom: 16px;
    font-family: 'Arial', sans-serif;
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
    line-height: 18px;
}

.custom-modal-footer{
    border: none;
    float: right;
}

#confirmation_model_button_right {
    background-color: rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, .87);
    margin-right: 14px ;
    margin-left:10px ;
}


