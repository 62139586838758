input {
    color: rgba(0, 0, 0, .87);
    background-color: rgba(0, 0, 0, .05);
    height: 50px;
}


select {
	height: 50px !important;
  	background-position:
		calc(100% - 14px) calc(1em + 7px),
		calc(100% - 16px) calc(1em + 2px),
		100% 0;
  	background-size: 12px;
	background-image: url("../images/iconDropdownArrowDark.svg");
	background-repeat: no-repeat;
	border-radius: 0;
	margin: 0;      
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance: none;
 }

 .autocomplete-input {
    width: 100%;
    max-width: 400px;
    border-style: none none solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, .54) rgba(0, 0, 0, .54) rgba(0, 0, 0, .38);
    background-color: rgba(0, 0, 0, .05);
    padding-left: 10px;
    background-position:
        calc(100% - 14px) calc(1em + 7px),
        calc(100% - 16px) calc(1em + 2px),
        100% 0;
    background-size: 12px;
    background-image: url("../images/iconDropdownArrowDark.svg");
    background-repeat: no-repeat;
    border-radius: 0;
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance: none;
 }

.drop-down {
    background-position:
        calc(100% - 14px) calc(1em + 7px),
        calc(100% - 16px) calc(1em + 2px),
        100% 0;
    background-size: 12px;
    background-image: url("../images/iconDropdownArrowDark.svg");
    background-repeat: no-repeat;
    border-radius: 0;
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance: none;
 }

 .drop-down.dark-text {
     background-image: url("../images/iconDropdownArrowLight.svg");
 }

.page-form {
    width: 600px;
    margin-top: 64px;
}

.form-group {
    margin-bottom: 32px;
}

.form-group.flex-display {
    margin-bottom: 0px;
    float: right;
    width: 100%;
    justify-content: space-between;
}

.form-control {
    width: 100%;
    height: 50px;
    max-width: 400px;
    display: block;
	box-shadow: none;
    -webkit-box-shadow: 0px;
    padding: 8px 12px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, .87);
    background-color: #fff;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    border-bottom: 2px solid rgba(0, 0, 0, .38);
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-radius: 4px 4px 0px 0px;
}

.form-control.insert {
    background-color: rgba(0, 0, 0, .05);
}

.form-control::placeholder,
.form-control.select.placeholder {
    color: rgba(0, 0, 0, .54);
}

.dark-text.form-control::placeholder,
.dark-text.form-control-textarea::placeholder,
.dark-text.form-control-alt-textarea::placeholder {
    color: rgba(255, 255, 255, .7);
}

.form-control.form-control-error {
    border-bottom: 2px solid #e65050 !important; /*overriding other styling when in error state*/
}

.form-control-textarea {
    width: 100%;
    min-height: 108px;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(0, 0, 0, .38);
    border-radius: 2px;
	background-color: rgba(0, 0, 0, .05);
	color: #555;
	box-shadow: none;
    padding: 8px 12px;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: rgba(0, 0, 0, 0.87);
    outline: none;
}

.form-control-textarea-error {
    border: 2px solid #e65050 !important; /*overriding other styling when in error state*/
}

.form-control-alt-textarea {
    width: 100%;
    min-height: 108px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, .38);
    border-radius: 2px;
	background-color: transparent;
	color: #555;
	box-shadow: none;
    padding: 8px 12px;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: rgba(0, 0, 0, 0.87);
}

.field-label {
    margin-bottom: 5px;
    color: rgba(0, 0, 0, .87);
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

.col-md-3 ~ .field-label {
    padding-left: 0px;
}

.textAreaContainer {
	max-width: 400px;
}

.textAreaModalLarge {
    max-width: none;
}

.textAreaCharCount {
    max-width: 400px;
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
    line-height: 18px;
    text-align: right;
}

.textAreaCharCountAlt {
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
    line-height: 18px;
    text-align: right;
}

.form-control:focus, .form-control-textarea:focus {
	border-color: #3385c2 !important;
    box-shadow: none;
    border-bottom-width: 2px !important;
	-webkit-box-shadow: 0px;
}

.dark-text {
    color: #fff;
}

.light-text {
    color: rgba(0, 0, 0, .87);
}

.form-control.dark-text {
    border-color: rgba(255, 255, 255, .38);
}

.form-control.light-text {
    border-color: rgba(0, 0, 0, .38);
}

.required-label {
	width: 20%;
	max-width: 400px;
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
    line-height: 18px;
	font-weight: 400;
	float: left;
	clear: both;
	margin-top: 15px;
}

.required-label.dark-text {
    color: rgba(255, 255, 255, .54);
    background-color: transparent;
}

.required-label.light-text {
    color: rgba(0, 0, 0, .54);
    background-color: transparent;
}

.formEditTextAnchorLink {
    color: rgba(0, 0, 0, .54);
}

.formEditTextAnchorLink:hover {
    color: rgba(0, 0, 0, .87);
}

.form-control:disabled {
    opacity: .38;
    background-color: #fff;
}

/* .user-detail-form {
	padding-top: 48px;
	padding-left: 90px;
} */

/* .empty-state-img {
	width:150px;
	display: block;
	margin: auto;
} */

.organization-text {
    color: rgba(0, 0, 0, .38);
    font-size: 10px;
    line-height: 10px;
}

.empty-state-text {
	font-family: 'Arial', sans-serif;
    color: rgba(0, 0, 0, .38);
    font-size: 12px;
    line-height: 18px;
    text-align: center;
}

::-webkit-input-placeholder {
    color: rgba(0, 0, 0, .54);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0px;
  box-shadow: 0px;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill.dark-text,
textarea:-webkit-autofill.dark-text,
select:-webkit-autofill.dark-text {
    -webkit-text-fill-color: #fff;
}

input:-webkit-autofill.light-text,
textarea:-webkit-autofill.light-text,
select:-webkit-autofill.light-text {
    -webkit-text-fill-color: rgba(0, 0, 0, .87);
}

@media (max-width: 767px) {
    .react-datepicker__input-container{
        display: block;
    }
}