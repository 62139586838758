.radio-button-container {
    padding-right: 15px;
    padding-bottom: 15px;
    padding-top: 5px;
}

.radio-button-container label {
    display: flex;
    align-items: center;
    height: 24px;
}

.radio-button-container input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.radio-button {
    position: absolute;
    opacity: 0.54;
    width: 24px;
    height: 24px;
    background-image: url("../images/iconRadioUncheckedDark.svg");
    outline: none;
}

.radio-button:checked {
    background-image: url("../images/iconRadioCheckedDark.svg");
    opacity: 1;
}

.radio-button:checked + .radio-button-label {
    color: rgba(0, 0, 0, .87);
}

.radio-button-label {
    padding-left: 40px;
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
}