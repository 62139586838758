.profile-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 97px 64px 0px 64px;
}

.profile-title {
    border-bottom: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-size: 28px;
    line-height: 28px;
}

.profile-event-logo {
    width: 60px;
    height: 60px;
    margin-right: 24px;
    border-radius: 4px;
}

.profile-subheader-container {
    width: 100%;
    min-width: 300px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.profile-subheader {
    display: flex;
    flex-direction: row;
}

.profile-subheader-info {
    width: 100%;
    float: left;
    display: block;
}

.profile-sub-text {
    font-size: 10px;
    display: flex;
    align-items: center;
}

.profile-selection-links {
    display: flex;
    align-items: center;
    font-size: 10px;
    flex-wrap: wrap;
}

.profile-link {
    height: 20px;
    padding: 0 5px;
	font-size: 10px;
    text-decoration: underline;
    white-space: nowrap;
}

.profile-link-container {
    display: flex;
    align-items: center;
}

.profile-header-add-img {
    width: 20px;
    height: 20px;
    opacity: 0.54; 
}

.profile-header-img-edit-container {
    height: 20px;
    width: 20px;
}

.profile-header-img-add-container {
    height: 20px;
    width: 20px;
    margin-left: 4px;
}

.profile-header-edit-img {
    width: 14px;
    opacity: 0.54;
}

.profile-header-edit-img:hover, .profile-header-add-img:hover {
    opacity: 0.87;
    cursor: pointer;
}

.profile-dashboard-header-tab-img {
    display: none;
}

.profile-dashboard-header-tab-active .profile-dashboard-header-tab-img {
    display: block;
}

.profile-link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.profile-link-active {
    padding: 0 5px;
	font-size: 10px;
	color: #3385c2;
    font-weight: bold;
    white-space: nowrap;
}

.profile-link-active:hover {
    cursor: pointer;
    color: #3385c2;
    font-weight: bold;
    text-decoration: none;
}

.profile-link-disabled {
    pointer-events: none;
}

.profile-container-noaccess-empty-state {
    margin-top:50px;
}

@media screen and (max-width: 767px) {
    .profile-container {
        padding: 97px 16px 0px 16px;
    }    
}