.event-dashboard-profile-container {
	width: 100%;
    margin-top: 32px;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
}

.dashboard-container {
    display: flex;
    width: 100%;
    height: 700px;
}

.metric-grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(160px, 16.2%));
    grid-auto-rows: 225px;
    grid-gap: 10px;
    min-height: 100%;
    margin: auto;
}

.metric-grid-footer {
    margin-top: 32px;
    width: 100%;
    padding-top: 32px;
    display: flex;
}

.metric-grid-footer-logo-icon {
    opacity: .10;
    width: 200px;
    margin-left: auto;
}

.metrics-open {
    display: grid;
    grid-template-columns: repeat(6, minmax(160px, 16.2%));
    grid-auto-rows: 225px;
    grid-gap: 10px;
    min-height: 100%;
}

.metrics-open .metric:hover{
    outline: 1px solid magenta;
    outline-offset: -1px;
}

.settings-open {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 225px;
    grid-gap: 10px;
    min-height: 100%;
    min-width: 191px;
    margin: auto;
}

.settings-open .metric:hover{
    outline: 1px solid magenta;
    outline-offset: -1px;
}

.settings-open .metric-selected {
    outline: 3px solid magenta;
    outline-offset: -3px;
}

.settings-open .metric-selected:hover {
    outline: 3px solid magenta;
    outline-offset: -3px;
}

/* Responsive / @media Styles */

@media (max-width: 1750px) {
    .settings-open {
        grid-template-columns: repeat(6, minmax(100px, 33%));;
    }

    .settings-open > .metric-3x2 {
        grid-column-end: span 3;
        grid-row-end: span 2;
        min-height: 450px;
    }
    .settings-open > .metric-3x1 {
        grid-column-end: span 3;
        grid-row-end: span 1;
        min-height: 225px;
    }
    .settings-open > .metric-2x2 {
        grid-column-end: span 3;
        grid-row-end: span 2;
        min-height: 450px;
    }
    .settings-open > .metric-2x1 {
        grid-column-end: span 3;
        grid-row-end: span 1;
        min-height: 225px;
    }
    .settings-open > .metric-1x1 {
        grid-column-end: span 2;
        grid-row-end: span 1;
        min-height: 225px;
    }
    .settings-open > .metric-1x2 {
        grid-column-end: span 2;
        grid-row-end: span 2;
        min-height: 450px;
    }
    .settings-open > .metric-1x3 {
        grid-column-end: span 2;
        grid-row-end: span 3;
        min-height: 675px;
    }
}

@media (max-width: 1400px) {
    .metric-grid {
        grid-template-columns: repeat(6, minmax(100px, 32.4%));
    }
}

@media (max-width: 1300px) {
    .metrics-open {
        grid-template-columns: repeat(6, minmax(80px, 25%));
    }
}

@media (max-width: 1200px) {
    .settings-open {
        grid-template-columns: repeat(6, minmax(60px, 16%));
    }

    .settings-open > .metric-3x2 {
        grid-column-end: span 6;
        grid-row-end: span 2;
        min-height: 450px;
    }
    .settings-open > .metric-3x1 {
        grid-column-end: span 6;
        grid-row-end: span 1;
        min-height: 225px;
    }
    .settings-open > .metric-2x2 {
        grid-column-end: span 6;
        grid-row-end: span 2;
        min-height: 450px;
    }
    .settings-open > .metric-2x1 {
        grid-column-end: span 3;
        grid-row-end: span 1;
        min-height: 225px;
    }
    .settings-open > .metric-1x1 {
        grid-column-end: span 3;
        grid-row-end: span 1;
        min-height: 225px;
    }
    .settings-open > .metric-1x2 {
        grid-column-end: span 3;
        grid-row-end: span 2;
        min-height: 450px;
    }
    .settings-open > .metric-1x3 {
        grid-column-end: span 3;
        grid-row-end: span 3;
        min-height: 675px;
    }
}

@media (max-width: 1144px) {
    .settings-open > .metrics-open {
        grid-template-columns: repeat(6, minmax(60px, 20%));
    }

}

@media (max-width: 991px) {
    .metric-grid {
        /* grid-template-columns: repeat(6, 1fr); */
        grid-template-columns: repeat(6, minmax(80px, 64.8%));
    }

    .metrics-open {
        grid-template-columns: repeat(6, minmax(60px, 30%));
    }

    .settings-open {
        grid-template-columns: repeat(6, minmax(20px, 15%));
    }

    .settings-open > .metric-3x2 {
        grid-column-end: span 6;
        grid-row-end: span 2;
        min-height: 450px;
    }
    .settings-open > .metric-3x1 {
        grid-column-end: span 6;
        grid-row-end: span 1;
        min-height: 225px;
    }
    .settings-open > .metric-2x2 {
        grid-column-end: span 6;
        grid-row-end: span 2;
        min-height: 450px;
    }
    .settings-open > .metric-2x1 {
        grid-column-end: span 6;
        grid-row-end: span 1;
        min-height: 225px;
    }
    .settings-open > .metric-1x1 {
        grid-column-end: span 6;
        grid-row-end: span 1;
        min-height: 225px;
    }
    .settings-open > .metric-1x2 {
        grid-column-end: span 6;
        grid-row-end: span 2;
        min-height: 450px;
    }
    .settings-open > .metric-1x3 {
        grid-column-end: span 6;
        grid-row-end: span 3;
        min-height: 675px;
    }
}

@media (max-width: 767px) {
    .metric-grid {
        grid-template-columns: repeat(6, 1fr);
    }

    .metrics-open {
        grid-template-columns: repeat(6, minmax(20px, 13%));
    }

    .settings-open {
        grid-template-columns: repeat(6, minmax(20px, 13%));
    }
}