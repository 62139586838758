/*
 * Base structure
 */

 html {
	width: 100%;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	margin: 0;
	font-family: 'Arial', sans-serif;
	background-color: #f4f4f4;
	font-size: 14px;
	overflow-x: hidden;
}

h1 {
	color: rgba(0, 0, 0, .87);
	font-weight: 700;
	font-size: 38px;
	line-height: 44px;
}

h2 {
	color: rgba(0, 0, 0, .87);
	font-weight: bold;
	font-size: 32px;
	line-height: 36px;
}

h3 {
	color: rgba(0, 0, 0, .87);
	font-weight: bold;
	font-size: 24px;
	line-height: 30px;
}

h4 {
	color: rgba(0, 0, 0, .87);
	font-weight: bold;
	font-size: 18px;
	line-height: 24px;
}

h5 {
	color: rgba(0, 0, 0, .87);
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
}

img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

img {
    border: 0;
}

a, a:active, a:hover {
	text-decoration: none;
	color: rgba(0, 0, 0, .87);
}

div {
	font-family: Arial, sans-serif;
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
    line-height: 18px;
}

/*
* Global add-ons
*/

#root {
	width: 100%;
	height: 100%;
	display: block;
}

.main-container {
	width: 100%;
	height: 100%;
	display: block;
	background-color: #f4f4f4;
}

.container {
	width: 100%;
	max-width: none;
	height: auto;
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
	flex: 0 1 auto;
	transition: all  98ms Cubic-bezier(0.4, 0.0, 0.6, 1);
	--webkit-transition: all 98ms Cubic-bezier(0.4, 0.0, 0.6, 1);
}

.container-open-sidebar {
	padding-left: 256px;
	transition: all  113ms Cubic-bezier(0.0, 0.0, 0.2, 1) 0s;
	--webkit-transition: all  113ms Cubic-bezier(0.0, 0.0, 0.2, 1) 0s;
}

.container-open-metric-settings {
	padding-right: 256px;
}

.container-fluid {
	width: 80%;
	display: block;
	margin: 132px auto 0px auto;
    padding: 0px;
}

/* .container-profile {
} */

.row {
	margin: 0;
}

.error {
    color: #C70039;
    border-bottom-color: #e65050;
    border-width: 2px;
}




/*
* Main content
*/

.main-notabs {
	width: 100%;
	display: block;
	margin: 128px auto;
}

.main .page-header {
	margin-top: 0;
}

.page-header {
	border-bottom: 0px;
	padding-bottom: 0px;
	margin-bottom: 0px;
}

/* .sub-header {
	margin-bottom: 7px;
} */

.header-sub-text {
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
    line-height: 18px;
}

.list-sub-heading {
	color: #3385c2;
    font-size: 12px;
	line-height: 18px;
	display: block;
	height: 64px;
    padding-top: 24px;
    padding-bottom: 24px;
}

/*
 * body elements
 */

.body-content-partial {
	padding-left: 8px;
}

.body-content-partial {
    margin-top: 32px;
    padding-left: 0px;
}

/*
 * buttons
 */
.btn {
	font-size: 14px;
	line-height: 16px;
	font-weight: 700;
    height: 36px;
    position: relative;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #3385c2;
	border-color: #3385c2;
	height: 36px;
    padding: 10px 12px;
    border-radius: 4px;
	min-width: 90px;
	margin: 7px 7px;
}

.btn-primary-no-margin-left,
.btn-primary-no-margin-left:hover,
.btn-primary-no-margin-left:focus,
.btn-primary-no-margin-left:active:focus,
.btn-primary-no-margin-left:active,
.btn-primary-no-margin-left.active,
.open>.dropdown-toggle.btn-primary-no-margin-left {
	color: #fff;
    background-color: #3385c2;
	border-color: #3385c2;
	height: 36px;
    padding: 10px 12px;
    border-radius: 4px;
	min-width: 90px;
	margin: 7px 7px 7px 0px;
}

.btn-primary-no-margin-right,
.btn-primary-no-margin-right:hover,
.btn-primary-no-margin-right:focus,
.btn-primary-no-margin-right:active:focus,
.btn-primary-no-margin-right:active,
.btn-primary-no-margin-right.active,
.open>.dropdown-toggle.btn-primary-no-margin-right {
	color: #fff;
    background-color: #3385c2;
	border-color: #3385c2;
	height: 36px;
    padding: 10px 12px;
    border-radius: 4px;
	min-width: 90px;
	margin: 7px 0px 7px 7px;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary:active,
.btn-secondary.active,
.open>.dropdown-toggle.btn-secondary {
    color: rgba(0, 0, 0, .87);
    background-color: hsla(0, 0%, 100%, .25);
	height: 36px;
    padding: 10px 12px;
    border-radius: 4px;
	min-width: 90px;
	margin: 7px 7px;
}

.btn-default {
	height: 36px;
    padding: 10px 12px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, .87);
    line-height: 16px;
    font-weight: 700;
    text-align: center;
	text-decoration: none;
	border: 0;
    margin: 7px 7px;
    min-width: 90px;
}

.btn-default-alt {
	height: 36px;
    padding: 10px 12px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, .87);
    line-height: 16px;
    font-weight: 700;
    text-align: center;
	text-decoration: none;
	border: 0;
	margin: 8px 0px;
}

.btn-alert,
.btn-alert:hover,
.btn-alert:focus,
.btn-alert:active {
    color: #fff;
    background-color: #e65050;
    border-color: #e65050;
	height: 36px;
	padding: 10px 12px;
	min-width: 90px;
	margin: 7px;
}

.btn:before {
    content: '';
    position: absolute;
    top: -7px;
    bottom: -7px;
    left: -7px;
    right: -7px;
}

.invisible-button {
	background-color: transparent;
	border: none
}

.list-document-image{
    height: 50px;
	padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    opacity: 0.54;
	text-decoration: none;
    height: 50px;
}

.list-document-info-container{
	width: 100%;
    margin-left: 5px;
    overflow: hidden;
}

.right-justify-buttons-modal{
    text-align: right;
    padding-right: 0px;
}

/*
* Placeholder dashboard ideas
*/

.placeholders {
	margin-bottom: 30px;
	text-align: center;
}
.placeholders h4 {
	margin-bottom: 0;
}
.placeholder {
	margin-bottom: 20px;
}
.placeholder img {
	display: inline-block;
	border-radius: 50%;
}

/*
 * Other
 */
.status-indicator {
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-right: 7px;
    float: left;
    border-radius: 7px;
    background-color: #65a943;
}

.status-div {
    font-family: 'Arial', sans-serif;
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
}

.custom-modal {
    width: 50%;
    min-width: 300px;
	margin: auto;
    padding: 16px;
    border-radius: 2px;
    background-color: #fff;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}

.modal-form {
	width: 100%;
    margin-top: 16px;
    max-width: 688px;
}

.modal-form-sidebar {
	width: 100%;
	height: 100%;
	display: block;
}

.modal-title {
	font-size: 18px;
    line-height: 24px;
	font-weight: bold;
	margin-top: 0px;
}

.required-label-modal {
	width: 20%;
	max-width: 400px;
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
    line-height: 18px;
	font-weight: 400;
	float: left;
    margin-top: 42px;
    align-items: center;
    flex-direction: row;
}

.user-details-page {
	height: 100%;
}

.user-avatar-div {
	width: 150px;
	margin-top: 57px;
}

.event-avatar {
	width: 150px;
	height: 150px;
    margin: 8px 0px 8px 0px;
    border-radius: 12px;
    background-color: #fff;
}

.modal-right-btns {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: flex-end;
	margin-top: 11px;
}

.list-document-image{
	height: 45px;
	padding-top: 5px;
	padding-right: 5px;
    padding-bottom: 7px;
    padding-left: 7px;
    opacity: 0.54;
	text-decoration: none;
}

.list-document-info-container{
	width: 100%;
    margin-left: 5px;
    overflow: hidden;
}

.list-group-document{
	width: 100% !important
}

/*
	Loading Animation Styles
*/ 

.loadingContainer {
	width: 100%;
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loadingIcon {
	width: 90px;
	height: 90px;
	opacity: 0.16;
	-webkit-animation:spin 1s linear infinite;
	-moz-animation:spin 1s linear infinite;
	animation:spin 1s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


/*
	CARD STYLES
*/

.card {
	min-width: 200px;
	border-radius: 2px;
	padding: 24px;
	background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .5);
    text-decoration: none;
}

/*  Added for Feature : Events Form */
.text-danger {
	font-size: 12px !important;
	color: #e65050 !important;
}

.event-sub-header {
	height: 64px;
	padding-top: 24px;
	padding-bottom: 24px;
	font-family: 'Arial', sans-serif;
	color: #3385c2;
	font-size: 12px;
	line-height: 18px;
	margin-top: 55px;
}

.flex-display {
	display: flex;
}

.flex-field {
    margin-left : 14px;
}

.align-right {
	margin-left: auto;
	float: right;
    padding: 0px;
}

.react-datepicker-wrapper input {
	/* border: none; */
	border-style: none none solid;
	border-width: 2px;
    border-color: rgba(0, 0, 0, .54) rgba(0, 0, 0, .54) rgba(0, 0, 0, .38);
    min-width: 193px;
}

.icon {
	width: 50px;
    height: 50px;
    padding: 13px;
    opacity: 1;
    cursor: pointer;
    opacity: 0.5;
}

.dropdown {
	position: absolute;
}

.dropdown-toggle::after {
    border-top: .43em solid;
    border-right: .43em solid transparent;
	border-left: .43em solid transparent;
	position: relative;
    top: 5px;
	float: right;
}

div[class^="styles_modal"], div[class*=" styles_modal"] {
    padding: 16px;
    border-radius: 2px;
    max-width: 720px;
}

/* Responsive / @media Styles */

/* @media (min-width: 767px) {
	.container {
		width: calc(100% - 256px);
	}
} */

@media (max-width: 991px) {
	.container {
		transition: all Cubic-bezier(0.4, 0.0, 0.6, 1) 253ms;
		--webkit-transition: all Cubic-bezier(0.4, 0.0, 0.6, 1) 253ms;
	}

	.container-open-sidebar {
		transition: all Cubic-bezier(0.0, 0.0, 0.2, 1) 293ms;
		--webkit-transition: all Cubic-bezier(0.0, 0.0, 0.2, 1) 293ms;
	}
}

@media (max-width: 767px) {
	.main-container {
		left: 0px;
	}

	.container {
		transition: all Cubic-bezier(0.4, 0.0, 0.6, 1) 195ms;
		--webkit-transition: all Cubic-bezier(0.4, 0.0, 0.6, 1) 195ms;
	}

	.container-open-sidebar {
		transition: all Cubic-bezier(0.0, 0.0, 0.2, 1) 225ms;
		--webkit-transition: all Cubic-bezier(0.0, 0.0, 0.2, 1) 225ms;
    }

    .flex-display{
        display: block;
        width: 100%;
    }

    .flex-field {
        margin-left: 0px;
    }

    .react-datepicker-wrapper{
        display: block !important; /* overriding datepicker node module CSS */
    }

    .react-datepicker__input-container{
        display: block !important; /* overriding datepicker node module CSS */
    }
}

@media (max-width: 991px) {
	.custom-modal {
		width: 100%;
	}
}

@media (max-width: 479px) {
	.custom-modal {
		width: 100%;
	}
}