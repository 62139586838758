.tabContainer {
    display: flex;
    width: 720px;
    height: 60px;
}


.tab {
  font-size: 14px;
  font-weight: bold;
  padding: 10px 60px;
  cursor: pointer;
  /* background: white; */
  border: 0;
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  justify-content: space-between;
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  width: 330px;
  line-height: 37px;
  height: 60px;
  text-align: center;
}

.profileAccessTab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    height: 24px;
    padding: 0px 4px;
    font-size: 10px;
    line-height: 10px;
    color: rgba(0, 0, 0, 0.54);
}

.profileAccessTab:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.profileAccessTabActive {
    background: white;
    border-bottom: 3px solid #3385c2;
    color: rgba(0, 0, 0, 0.87);
}

.button:focus {
    outline: none;
}
