.notification {
    display: flex;
    width: calc(99% - 256px);
    height: 36px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 7px;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .5);
    position: fixed;
    bottom: 36px;
}

.fade-in {
    margin-top: 25px;
    font-size: 21px;
    text-align: center;
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
    from {
        opacity:0;
        bottom: 0px;
    }
    to {
        opacity:1;
        bottom: 36px;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
        bottom: 0px;
    }
    to {
        opacity:1;
        bottom: 36px;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
        bottom: 0px;
    }
    to {
        opacity:1;
        bottom: 36px;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
        bottom: 0px;
    }
    to {
        opacity: 1;
        bottom: 36px;
    }
}

.notificationText {
    height: 24px;
    font-family: 'Arial', sans-serif;
    color:#fff;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
}

.notificationContainer{
    width: 100%
}

.success{
    background-color: #65a943;
}

.error{
    background-color: red;
}

@media (max-width: 768px) {
    .notification {
        left: 4px;
        width: 99%;
    }
}

