.checkbox {
  margin: 15px;
  height: 24px;
}

input[type=checkbox] {
  display: none;
}

.checkbox label {
  position: relative;
  padding-left: 40px;
  color:rgba(0, 0, 0, .54);
  height: 100%;
  line-height: 24px;
}

input[type=checkbox]:checked + label {
  color:rgba(0, 0, 0, .87);
}

.checkbox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url("../images/iconCheckboxUncheckedDark.svg") left center no-repeat;
  width: 24px;
  height: 24px;
  opacity: 0.54;
}

input[type=checkbox]:checked + label:before {
  background: url("../images/iconCheckboxCheckedDark.svg") left center no-repeat;
  opacity: 0.87;
}