/* * {
	outline: 1px dotted #112342;
} */

.toggle-input-div {
    display: flex;
    align-items: center;
    font-family: 'Arial', sans-serif;
    font-size: 12px;
	line-height: 18px;
}

.toggle-input-div.inactive-toggle {
    opacity: 0.38;
}

.toggle-input {
    display: flex;
    align-items: center;
    width: 50px;
    height: 50px;
}

.toggle-interior {
    height: 24px;
    width: 100%;
    padding-top: 3px;
    padding-right: 3px;
    padding-left: 3px;
    border-radius: 12px;
    background-color: #14b1e7; 
}

.toggle-interior.toggle-interior-off {
    background-color: rgba(0, 0, 0, .38);
}

.toggle-switch {
    width: 18px;
    height: 18px;
    float: right;
    border-radius: 9px;
    background-color: #fff;
}

.toggle-switch.toggle-switch-off {
    float: left;
    background-color: hsla(0, 0%, 100%, .75);
}

.toggle-label.toggle-label-off {
    color: rgba(0, 0, 0, .54);
}

.toggle-label {
    margin-left: 10px;
    color: rgba(0, 0, 0, .87);
}