.sidebar {
	width: 256px;
	min-width: 256px;
	max-width: 256px;
	min-height: 100vh;
	display: inline-block;
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: -256px;
	z-index: 1000;
	overflow-x: hidden;
	overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
	background-color: #ffffff;
    padding: 0px;
	transition: all  98ms Cubic-bezier(0.4, 0.0, 0.6, 1);
	--webkit-transition: all 98ms Cubic-bezier(0.4, 0.0, 0.6, 1);
}

.sidebar-link {
    align-items: center;
	height: 64px;
	font-weight: 700;
}

.sidebar-text {
	margin-left: 10px;
	font-size: 14px;
	line-height: 20px;
    font-family: 'Arial', sans-serif;
    color: rgba(0, 0, 0, .87);
    font-weight: 700;
    text-decoration: none;
}

.logomain {
	margin-top: 7px;
    height: 50px;
    float: none;
}

.sidebar-header {
    min-width: 256px;
    min-height: 96px;
    max-height: 96px;
	background-color: #fff;
}

.sidebar-brand {
	margin-left: 11px;
	margin-top: 3px;
}

.sidebar-open {
    left: 0px;
    transition: all  113ms Cubic-bezier(0.0, 0.0, 0.2, 1) 0s;
	--webkit-transition: all  113ms Cubic-bezier(0.0, 0.0, 0.2, 1) 0s;
}

/* Sidebar navigation */

.sidebar-list {
	display: block;
	width: 256px;
	margin-bottom: 20px;
	list-style: none;
	flex-wrap: wrap;
    padding-left: 0;
}

.sidebar-list > li > a {
	display: flex;
	clear: both;
}

.sidebar-list > li > a {
	padding: 7px;
}

.sidebar-list > li > a:hover {
    text-decoration: none;
    background-color: #f4f4f4;
}

li.active .sidebar-text {
    color: #3385c2;
}


/* @media (max-width: 767px) { 
	.sidebar {
		display: none;
	}
 } */