#tooltip-content {
  color: white;
  font-size: 12px;
  line-height: 18px;
}

.tippy-tooltip.tooltip-theme {
  background: rgba(0, 0, 0, .65);
  padding: 10px;
  border-radius: 2px;
  color: white;
}
  
.tippy-tooltip.tooltip-theme  .tippy-backdrop { 
  background: rgba(0, 0, 0, .65);
}

.tippy-tooltip.hex-tooltip-light {
  background: white;
  padding: 16px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}
  
.tippy-tooltip.hex-tooltip-light  .tippy-backdrop { 
  background: white;
}

.tippy-tooltip.hex-tooltip-dark {
  background: rgba(35,34,35);
  padding: 16px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}
  
.tippy-tooltip.hex-tooltip-dark .tippy-backdrop { 
  background: rgba(35,34,35);
}



