/*
	LIST STYLES
*/


.list-group-item:last-child,
.list-group-item:first-child {
	border-radius: 0px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.list-item {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid rgba(0, 0, 0, .1);
	cursor: pointer;
}

.list-item:hover {
	background-color: rgba(0, 0, 0, .05);
}

.list-item:hover .list-image-section {
    opacity: 1;
}

.list-item-1 {
	height: 64px;
}

.list-item-2 {
    height: 80px;
    align-items: center;
}

.list-item-3 {
	height: 96px;
}

.list-group-item {
	width: 74%;
	height: 96px;
	background-color: transparent;
	position: relative;
	display: flex;
	/* float: left; */
	padding: 18px 8px 18px 8px;
	margin: 0;
	border: none;
	border-radius: 0px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	box-shadow: none;
	text-decoration: none;
	transition: background-color .2s;
    z-index: 98;
    overflow: hidden;
}

.list-group-item-simple {
	width: 74%;
	height: 80px;
	background-color: transparent;
	position: relative;
	display: flex;
	/* float: left; */
    padding: 15px 8px 15px 8px;
	margin: 0;
	border: none;
	border-radius: 0px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	box-shadow: none;
	text-decoration: none;
	transition: background-color .2s;
    z-index: 98;
    overflow: hidden;
}

.list-avatar-div {
	display: block;
	width: 66px;
	height: 100%;
	float: left;
    margin: 0 auto;
}

.list-avatar-image {
	display: block;
	width: 50px;
	height: 50px;
	margin-top: 5px;
	margin-right: auto;
	margin-left: auto;
	border-radius: 4px;
	background-color: #fff;
}

.list-document-image {
	display: block;
	width: 50px;
	height: 50px;
	margin-right: auto;
	margin-left: auto;
	border-radius: 4px;
	background-color: #fff;
}

.list-title {
    overflow: hidden;
    font-family: 'Arial', sans-serif;
    color: rgba(0, 0, 0, .87);
    font-size: 18px;
    line-height: 24px;
	font-weight: 700;
    margin: 0px;
    overflow: hidden;
    white-space: nowrap;
}

.list-other-items-div {
	width: 25px;
	min-width: 25px;
	height: 25px;
	display: block;
	margin-top: 20px;
	margin-right: 10px;
	margin-left: 10px;
    /* padding: 13px; */
    float: right;
}

.list-other-items-image {
	display: block;
    width: 25px;
    height: 25px;
    opacity: 0.5;
}

.list-info-container {
	width: 100%;
	margin-left: 3px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.list-info-details {
    width: 100%;
}

.list-image-section {
	width: 20%;
	min-width: 138px;
	height: 96px;
    position: relative;
    padding-top: 15px;
    float: right;
    opacity: 0;
}

.list-group {
	display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 50px;
}