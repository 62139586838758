.insights-menu-container {
    width: 256px;
    height: 100%;
    margin-left: 8px;
}

.insights-menu-header {
    height: 10%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
}

.insights-menu-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 7px 7px 7px 20px;
}

.insights-menu-header-content h5 {
    margin-bottom: 0;
}

.insights-menu-header-icon-add {
    width: 50px;
    height: 50px;
    opacity: 0.54;
    padding: 13px;
}

.insights-menu-header-icon-add:hover {
    cursor: pointer;
    opacity: 0.87;
}

.insight-item-container {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #F4F4F4;
}

.insight-item-title {
    margin: 0;
}

.insight-item-date, .insight-item-signature {
    font-size: 10px;
    margin: 0;
    line-height: 16px;
}

.insight-item-header-container {
    display: flex;
    flex-direction: column;
}

.insight-item-body {
    margin-top: 16px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
    line-height: 18px;
    white-space: pre-line;
    overflow-wrap: break-word;
}

.insight-item-action-container {
    height: 20px;
    width: 100%;
    display: flex;
    align-items: center;
}

.insight-item-action-container > img {
    width: 20px;
    height: 20px;
    opacity: 0.54;
}

.insight-item-action-container > .edit {
    margin-left: auto;
}

.insight-item-action-container > .edit:hover {
    opacity: 0.87;
    cursor: pointer
}

.insights-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.insights-empty-state-image {
    width: 50px;
    opacity: 0.38;
    margin-top: 32px;
    margin-bottom: 8px;
}

.insights-empty-state-text {
    margin-top: 8px;
    color: rgba(0,0,0,0.38);
    line-height: 18px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
}

.insights-menu-insight-list-container {
    height: 90%;
    overflow-y: scroll;
}

.insights-menu-drag-active {
    background-color: rgba(0,0,0,.5);
}