.container-fluid.no-permissions-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
}

.vertical-center {
    min-height: 100%;
    min-height: 100vh;
  
    display: flex;
    align-items: center;
}

.vertical-center-inner {
    margin: -75px auto 0px auto;
}

a.no-permissions-link, a.no-permissions-link:active, a.no-permissions-link:hover {
	text-decoration: none;
	color: #0066b3
}

.no-permissions-desc {
    max-width: 400px;
    margin: 16px 0px 16px 0px;
}

/* Load Bar */

.load-bar {
    position: relative;
    width: 100%;
    height: 5px;
    background-color: rgba(20, 177, 231, .50);
    overflow: hidden;
}

.load-bar.with-header {
    margin-top: 64px; /* with-header used so load-bar could be used outside of no_permission */
}

.load-bar.with-header + .vertical-center {
    min-height: calc(100vh - 64px); /* adjusting for 64px margin of header */
}

.load-bar.with-header + .vertical-center > .vertical-center {
    margin-top: -107px; /* adjusting for 64px margin of header */
}

.bar {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center;
}

.bar:nth-child(1) {
    background-color: #14b1e7;
    animation: loadingBar 2s linear infinite;
    animation-timing-function: cubic-bezier(0.4, 0.0, 0.6, 1);
}
  
@keyframes loadingBar {
    0% {left: 0; width: 0; border-top-left-radius: 4px; border-bottom-left-radius: 4px;}
    20% {left: 0;}
    100% {left: 100%; width: 50%; border-bottom-right-radius: 4px; 
    border-top-right-radius: 4px;}
}