.container-empty-state {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: auto;
    grid-column-end: span 6;
    grid-row-end: span 1;
    align-self: center;
    height: 100%;
}

.image-empty-state {
    width: 45%;
    align-self: center;
    opacity: .16;
}

.support-empty-state {
    align-self: center;
}

.text-empty-state {
    margin: 12px auto;
    font-size: 12px;
    line-height: 18px;
    grid-column-end: span 6;
    grid-row-end: span 1;
    opacity: .38;
    text-align: center;
}

.light {
    color: #000000;
}

.dark {
    color: #ffffff;
}
