.messageText {
    margin-left: 24px;
    color: rgba(0, 0, 0, 1);
    height: 16px;
    margin: 23px 30px 3px 10px;
    object-fit: contain;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.54);    
}

.messageTextSecondary {
    margin-left: 32px;
    height: auto;
}

.sendTempPasswordResultsContainer {
    white-space: pre-line;
    display: flex;
    margin-left: 16px;
}

.sendTempPasswordErrorResultsContainer {
    white-space: pre-line;
    margin-left: 16px;
    max-width: 450px;
}

.sendTempPasswordContainer {
    display: flex;
}

.setPasswordResultsContainer {
    white-space: pre-line;
    display: flex;
    margin-left: 250px;
}

.setPasswordErrorResultsContainer {
    white-space: pre-line;
    margin-left: 250px;
}

.cognitoResultsMainContainer {
    display: flex;
}

.cognitoResultsContainer2 {
    display: flex;
}

.setPasswordContainer {
    display: flex;
    margin-top: 20px;
}

.setPasswordButton {
    width: 220px;
}

.setPasswordCopiedText {
    margin-top: 20px;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.54);
}

.setPasswordInput {
    margin-left: 20px;
    width: 250px;
}

.icon {
    width: 24px;
    padding-top: 10px;    
}

.passwordSettingsContainer {
    margin-top: 50px;
    padding-bottom: 100px;
}

.cognitoSettingsContainer {
    display: flex;
    margin-top: 20px;
    margin-left: 7px;
}

.cognitoSettingsValue {
    margin-left: 10px;
    font-style: italic;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.iconCopyPassword {
    padding: 5px;
    opacity: 1;
}

.userPasswordSettingsMainContainer {
    margin-left: 150px;
}

.userSettingsMainContainer {
    display: flex;
}

.userSettingsFormOuterContainer {
    flex: 1;
}

.userSettingsFormInnerContainer {
    margin-left: 30px;
}

.userSettingsForm {
    margin-top: 57px;
}


#userSettingFormButton
{
    margin-left: auto;
	float: right;
    padding: 0px;
}

