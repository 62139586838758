.event-access-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 16px;
}

.event-access-add-icon {
    height: 20px;
    opacity: .54;
    padding-left: 10px;
}

.event-access-info-text {
    max-width: 400px;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: rgba(0, 0, 0, .38);
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 8px;
}

.event-access-user-container {
    overflow-y: scroll;
    height: 175px;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(0, 0, 0, .1);
    border-radius: 4px;
}

.event-access-user-container {
    overflow-y: scroll;
    height: 175px;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(0, 0, 0, .25);
    border-radius: 4px;
    margin-bottom: 10px;
}

.event-access-no-content {
    align-items: center;  
    border-color: rgba(0, 0, 0, .1);  
    border-radius: 4px;
    border-style: dashed;
    border-width: 2px;
    display: flex;
    height: 175px;
    justify-content: center;
    margin-bottom: 10px;
}

.event-access-no-content img {
    display: block;
    height: 35px;
    margin: auto;
    margin-bottom: 8px;
    opacity: 0.16;
}

.event-access-no-content-text {
    font-size: 10px;
    line-height: 14px;
}

.event-access-no-content div {
    color: rgba(0, 0, 0, .38); 
}

.event-access-btn-remove {
    background-color: #e65050;
    color: white;
}

.event-access-drop-down {
    width: 100%;
    height: 50px;
    max-width: 400px;
    margin-bottom: 8px;
    padding-top: 7px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: rgba(0, 0, 0, 0.54) rgba(0, 0, 0, 0.54) rgba(0, 0, 0, 0.38);
    border-radius: 4px 4px 0px 0px;
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Open Sans', sans-serif;
    color: rgba(0, 0, 0, 0.87);
    display: inline-block;
    vertical-align: middle;
    position: relative;
}


.event-access-drop-down > span {
    display: block;
    position: relative;
    cursor: pointer;
    padding: 1em;
    white-space: nowrap;
    overflow: hidden;
}

.event-access-drop-down > div {
    background-color: white;
    visibility: hidden;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    max-height: 300px;
    position: relative;
    z-index: 1;
}

.event-access-drop-down-active > div {
    visibility: visible;
}

.event-access-drop-down-options {
    list-style: none;
    padding: 0;
    cursor: pointer;
}

.event-access-drop-down-options li:hover  {
    background-color: rgba(0, 0, 0, 0.05);
}