.dashboard-container-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 700px;
    border-style: dashed;
    border-width: 4px;
    border-color: rgba(0, 0, 0, 0.1);
}

.dashboard-image-empty {
    width: 150px;
    margin-bottom: 32px;
    opacity: 0.38;
}

.dashboard-content-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
}

.dashboard-header-empty-state {
    margin: 0;
    color: rgba(0, 0, 0, 0.54);
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
}

.dashboard-text-empty-state {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.38);
    font-size: 12px;
    line-height: 18px;
    text-align: center;
}