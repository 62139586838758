/* Tab Styles  */

.nav {
	display: block;
}

.admin-tab-subheader {
	margin: 0 auto;
	padding: 0px;
    background-color: #2f2d2e;
    position: fixed;
	top: 64px;
	left: 0;
    right: 0;
    height: 64px;
	display: inline-block;
	z-index: 800;
	border: 0;
	padding: 0px;
    text-align: center;
    transition: all  98ms Cubic-bezier(0.4, 0.0, 0.6, 1);
	--webkit-transition: all 98ms Cubic-bezier(0.4, 0.0, 0.6, 1);
}

.tab-open-sidebar {
    left: 256px;
    transition: all  113ms Cubic-bezier(0.0, 0.0, 0.2, 1) 0s;
	--webkit-transition: all  113ms Cubic-bezier(0.0, 0.0, 0.2, 1) 0s;
}

.tabs-spacer {
    margin-top: 196px;
}

/* .tab { */
.nav-item {
    width: 33.3%;
    height: 64px;
    max-width: 375px;
    min-width: 100px;
	padding-top: 24px;
    display: inline-block;
	flex-direction: row;
	flex-grow: 1;
	justify-content: center;
    position: relative;
    font-family: 'Arial', sans-serif;
    color: hsla(0, 0%, 100%, .7);
	line-height: 16px;
	font-size: 14px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: none;
}

.nav-item-active {
    border-bottom: 5px solid #3385c2;
    color: #fff;
}

.nav-item-active:hover {
    color: #fff;
}

.admin-tab-body {
	padding-top: 0px;
	/* margin-left: 100px; */
}

.tab-inner-container {
    display: flex;
    position: relative;
    overflow: hidden;
    height: 64px;
    max-width: 750px;
    min-width: 300px;
    margin: 0 auto;
	padding: 0px 7px;
}