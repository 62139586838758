.profile-dashboard-header-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    height: 24px;
    padding: 0px 4px;
    font-size: 10px;
    line-height: 10px;
    color: rgba(0, 0, 0, 0.54);
}

.profile-dashboard-header-tab:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.profile-dashboard-header-tab-active {
    border-bottom: 2px solid #3385c2;
    color: rgba(0, 0, 0, 0.87);
}

.profile-dashboard-header-tab-img {
    width: 14px;
    opacity: 0.54;
    display: none;
}

.profile-dashboard-header-tab-active .profile-dashboard-header-tab-img {
    display: block;
}

.profile-dashboard-header-tab-img:hover {
    opacity: 0.87;
}