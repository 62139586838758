.profile-header-modal {
    display: block;
    width: 50%;
    max-width: 720px;
    min-width: 300px;
    margin: auto;
    /* padding: 16px; */
    padding: 0px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .5);
}

.profile-header {
    display: block;
    width: 50%;
    max-width: 720px;
    min-width: 300px;
    margin: auto;
    /* padding: 16px; */
    /* only for ugly tabs */
    padding: 0px !important;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .5);
}

@media (max-width: 991px) {
    .profile-header-modal {
        width: 75%;
    }
}