.navbar {
	width: 100%;
	height: 64px;
	z-index: 900;
	border: 0;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	margin-bottom: 0px;
	padding: 0px 7px;
	border:none;
    background-color: #2f2d2e;
    transition: all  113ms Cubic-bezier(0.0, 0.0, 0.2, 1) 0s;
	--webkit-transition: all  113ms Cubic-bezier(0.0, 0.0, 0.2, 1) 0s;    
}

.sidebar-spacer {
    left: 256px;
    transition: all  98ms Cubic-bezier(0.4, 0.0, 0.6, 1);
	--webkit-transition: all 98ms Cubic-bezier(0.4, 0.0, 0.6, 1);
}

.icon {
	width: 50px;
    height: 50px;
    padding: 13px;
	opacity: 1;
	cursor: pointer;
}

.icon-menu {
	opacity: 0.5;
	padding: 13px;
	width: 50px;
	height: 50px;
	float: left;
}

.icon-bar {
	margin-bottom: 5px;
	background-color: #fff;
}

#navbar {
	display: block;
	background-color: #2f2d2e;
}

.navbar-toggle {
	border: 0px;
	display: block;
	position: relative;
	padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
	border-radius: 4px;
    float: left;
}

.navbar-toggle:hover {
	cursor: pointer;
}

.navbar-toggle:hover, .navbar-toggle:focus {
	background-color: transparent! important;
	outline: none;
}

.navbar-right {
    float: right;
	margin-right: -15px;
	display: block;
}

.navbar-left {
	float: left;
    display: block;
}

.navbar-toggle .icon-bar {
    display: block;
    width: 24px;
    height: 2px;
    border-radius: 0px;
}

.navbar-form {
	display: flex;
	align-items: center;
	margin-top: 0px;
	margin-bottom: 0px;
	border: none;
	outline: none;
	box-shadow: none;
	padding: 7px 15px;
}

.navbar-container {
    margin: 5px 0px 0px 2px;
}

.profileHeaderIcon {
	width: 50px;
    height: 50px;
    padding: 13px;
	opacity: 1;
	cursor: pointer;
	border: none;
	background-color: transparent;
}

.profileHeaderSaveAndClose {
	width: 150px;
	height: 50px;
    float: right;
	color: hsla(0, 0%, 100%, .7);
	font-family: 'Arial', sans-serif;
    font-size: 12px;
	line-height: 18px;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.profileHeaderIcon:focus, .profileHeaderSaveAndClose:focus {
	outline: none;
	text-decoration: none;
}

@media (max-width: 767px) { 
	.profile-header-icon-edit {
         display: none; 
    }       
}


