.event-settings-form {
    margin-bottom: 256px;
}

.form-required-label {
    color: rgba(0, 0, 0, .54);
    margin-top: 16px;
    float: left;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 18px;
}